@import "common";
@import '_common.scss';

.report-container {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  .settlement-button {
    margin-right: 20px;
  }

  .report-card {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.15);
    margin: 25px 20px;

    @media screen and (max-width: $mobile_width) {
      margin: 10px;
    }

    .card-header {
      display: flex;
      justify-content: space-between;
      padding: 15px 25px;

      &-right {
        display: flex;
      }

      @media screen and (max-width: $mobile_width) {
        flex-direction: column;
        padding: 15px 5px;
      }

      .month-controls {
        display: flex;
        width: 350px;
        margin-right: 20px;

        @media screen and (max-width: $mobile_width) {
          width: 100%;
        }

        .year-selector {
          margin-left: 20px;
        }
      }

      h2 {
        margin: 0;
        font-weight: normal;
        color: $dark-gray;
        font-family: 'Roboto', sans-serif;
      }
    }

    .card-body {
      .graph {
        @extend .flex-centered;
        color: $dark-gray;
        height: 500px;
        padding: 20px 40px;
        width: 100%;

        @media screen and (max-width: $mobile_width) {
          padding: 10px;
        }
      }
    }

  }
}
