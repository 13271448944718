@import "common";
@import '_common.scss';

.metrics-ribbon {
  width: 100%;
  height: 114px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid rgba(209, 209, 209, 0.5);
  border-bottom: 1px solid rgba(209, 209, 209, 0.5);

  &-cell {
    border-right: 1px solid $light-gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    &:last-of-type {
      border-right: none;
    }

    .value {
      font-size: 42px;
      color: #0079C1;

      @media screen and (max-width: $mobile_width) {
        font-size: 30px;
      }
    }

    .unit {
      font-size: 18px;
      color: #9B9B9B;
      margin-left: 10px;
    }

    .type {
      font-size: 18px;
      color: #9B9B9B;
    }
  }
}
