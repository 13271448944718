@import "common";
@import '_common.scss';

.ReactTable {
  width: 100%;

  .-pagination .-btn {
    background-color: $navy-blue;
    color: $white;
  }

  .-pagination .-btn:not([disabled]):hover {
    background-color: $dark-blue;
  }

  .rt-resizable-header-content {
    font-size: $body-text;
    white-space: normal;
  }

  .rt-table {
    overflow: visible;

    .rt-tbody {
      overflow: visible;
    }

    .rt-td {
      align-self: center;
      text-align: center;
      overflow: visible;

      .icon-btn-container {
        display: flex;
        justify-content: center;
      }
    }
  }
}
