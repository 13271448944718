@import "common";
.bids-offers {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .control-container {
    align-items: center;
    border-bottom: 1px solid darkgray;
    display: flex;
    justify-content: space-around;

    .wrapped-select {
      max-width: 300px;
    }

    .event-display-container {
      width: 400px;
    }
  }

  .chart-container {
    display: flex;
    height: 300px;
    margin-bottom: 20px;
    width: 100%;
  }

  .center-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
