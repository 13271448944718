@import "common";
@import '_common.scss';

.my-assets {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #FAFAFA;

  &-header {
    align-items: center;
    background-color: $white-smoke;
    display: flex;
    height: 72px;
    padding: 0 28px;
    width: 100%;

    h2 {
      color: $dark-blue;
      font-weight: 500;
      margin: 0;
    }
  }

  &-navigation {
    background-color: #fafafa;
    border-bottom: 1px solid $very-light-gray;
    height: 72px;
    width: 100%;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      flex-grow: 1;
    }

    .right {
      display: flex;
    }

    .control-btn {
      margin-left: 20px;
    }

    .back-navigation-btn {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      color: #9B9B9B;
      cursor: pointer;
      font-size: 18px;
      height: 100%;
      text-decoration: none;
      width: 100%;

      i {
        padding-right: 10px;
      }
    }
  }

  .header {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    width: 180px;
  }

  &-contents {
    background-color: #fafafa;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &-details {
    display: flex;
    flex-direction: column;
    padding-left: 35px;
    padding-right: 35px;

    @media screen and (max-width: $mobile_width) {
      padding-left: 10px;
      padding-right: 10px;
    }

    .asset-header {
      display: flex;
      align-items: center;
    }

    .asset-status {
      @extend .flex-centered;
      border: 1px solid $header-blue;
      border-radius: 14px;
      color: $header-blue;
      height: 29px;
      font-size: 15px;
      font-weight: 500;
      margin: 0 15px;
      width: 101px;
    }

    .asset-name {
      display: flex;
      align-items: center;
      height: 65px;
      min-width: 200px;

      h2 {
        color: $dark-gray;
        font-weight: 500;
        font-size: 24px;
        margin: 0;
      }
      svg {
        align-self: flex-start;
        width: 200px;
      }
    }

    .asset-properties-container {
      display: flex;
      flex-direction: row;

      .asset-properties {
        display: flex;
        flex-direction: column;
      }

      .asset-standing-offer {
        display: flex;
        flex: 1 1 auto;
        padding-left: 4%;
        width: 100%;
      }
    }

    .asset-error {
      color: red;
    }

    .property-row {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 21px;
      margin-top: 11px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    .property-loading {
      svg {
        height: 16px;
      }
    }

    .property-label {
      color: $light-header-blue;
      width: 205px;
      flex-shrink: 0;
    }

    .property-value {
      color: $dark-gray;

      .editing {
        input {
          width: 90px;
        }
      }
    }
  }

  &-graph-container {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 28px;
    padding-right: 28px;
    width: 100%;
    height: 350px;

    @media screen and (max-width: $mobile_width) {
      padding: 0 5px;
    }
  }

  %asset-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 28px;
    font-size: 18px;
    color: $dark-gray;

    .asset-header {
      font-size: 22px;
    }

    .auto-respond-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .file-form-input__label {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 40px;
      width: 80px;
    }

    .file-form-btn {
      height: 100%;
      width: 100%;
    }

    .file-upload-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .graph-date-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: baseline;
      width: 350px;
      
      .year-selector {
        margin-left: 20px;
      }
    }

    .tip-span {
      margin-right: 6%;
    }
  }

  .asset-row {
    @extend %asset-row;
  }

  .asset-row__first {
    @extend %asset-row;
    border-top: 1px solid $light-gray-border;

    @media screen and (max-width: $mobile_width) {
      padding: 10px;
      flex-direction: column;
      align-items: flex-start;
    }
  }


  .react-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &__tab-list {
      display: flex;
      flex-direction: row;
      font-size: 18px;
      padding: 0 28px;
      margin: 0px;
      border-bottom: 1px solid $light-gray-border;
    }

    &__tab {
      display: inline-block;
      border-bottom: none;
      list-style: none;
      width: 200px;
      max-width: 200px;
      padding-top: 22px;
      padding-bottom: 22px;
      text-align: center;
      cursor: pointer;
      color: $dark-gray;

      &--selected {
        border-bottom: 5px solid $medium-blue;
        color: $medium-blue;
      }

      &--disabled {
        cursor: default;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 5px hsl(208, 99%, 50%);
        border-color: hsl(208, 99%, 50%);
      }
    }

    &__tab-panel {
      display: none;

      &--selected {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }
  }
}
