@import "common";
.icon-btn-container {
  .icon-btn {
    @extend .flex-centered;
    cursor: pointer;
    height: 24px;
    width: 24px;
    padding: 0;

    &.custom-btn {
      background-color: transparent;

      .icon-btn {
        :hover {
          background-color: #f5f5f5;
  
          .icon-container {
            color: #707070;
          }
        }

        :disabled,
        :disabled:hover {
          background-color: transparent;
          .icon-container {
            color: #e2e2e2;
          }
        }

        &-active,
        &-active:hover {
          .icon-container {
            & > i {
              color: #00b4aa;
            }
      
            & svg {
              fill: #00b4aa;
            }
          }
        }
      }
    }

    &.round {
      border-radius: 50%;
    }

    .icon-container {
      @extend .flex-centered;
      color: #959595;
      height: 24px;
      width: 24px;
      font-size: $icon-size;
      .material-icons {
        font-size: inherit;
      }
    }

    &:disabled {
      cursor: not-allowed;

      .icon-btn-image {
        background-color: transparent;
      }
    }
  }
}
