$icon-size: 24px;
$headline-text: 22px;
$title-text: 18px;
$body-text: 15px;
$caption-text: 12px;

$weights: (
  caption-text: 400,
  body-text: 400,
  title-text: 500,
  headline-text: 500,
);

$sizes: (
  caption-text: 12px,
  body-text: 15px,
  title-text: 18px,
  headline-text: 22px,
);
