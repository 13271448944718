@import "common";
@import '_common.scss';

.simple-number-input {
  &__label {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 2px;
    width: 100%;
  }

  &-label-text {
    flex-shrink: 2;
    font-size: $body-text;
  }
  
  &__input {
    align-items: center;
    flex-shrink: 1;
    margin: 0px 4px;
    justify-content: space-between;
    max-width: 90%;
    
    background: white;
    border: 1px solid $light-gray;
    border-radius: 4px;
    color: $dark-charcoal;
    font-size: $body-text;

    &:disabled {
      background-color: $light-gray;
      color: $dark-gray;
      cursor: not-allowed;
    }
  }

  &-unit {
    flex-shrink: 2;
    font-size: $body-text;
  }
}
