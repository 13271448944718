@import "common";
@import '_common.scss';

.my-profile {
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 100%;

    .content-container {
        height: 100%;
        display: flex;
        flex-direction: row;
        background-color: $white-smoke;
        overflow-y: auto;

        @media screen and (max-width: $mobile_width) {
            flex-direction: column;
        }
    }

    .column-1 {
        width: 66%;
        padding-left: 28px;
        padding-top: 23px;
        padding-right: 13px;

        @media screen and (max-width: $mobile_width) {
            padding: 10px;
            width: 100%;
        }
    }

    .column-2 {
        padding-left: 13px;
        padding-top: 23px;
        padding-right: 37px;
        width: 33%;

        @media screen and (max-width: $mobile_width) {
            padding: 10px;
            width: 100%;
        }
    }
}