@import "common";
@import '_common.scss';

.lmpchartpanel {
  padding: 30px;

  @media screen and (max-width: $mobile_width) {
    padding: 10px;
  }

  .no-graph-data {
    margin-top: 30px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #A0A0A0;

    & > div { text-align: center; }

    .material-icons {
      width: 100px;
      color: #eee;
      font-size: 100px;
    }

    h4 {
      margin: 0;
      margin-bottom: 5px;
      font-weight: 500;
    }

    p {
      font-size: 14px;
    }
  }


  header {
    display: flex;

    @media screen and (max-width: $mobile_width) {
      flex-direction: column;
      margin-bottom: 0;
      align-items: center;
    }

    .title {
      color: #0079C1;
      font-weight: 500;
      font-family: Roboto;
      margin: 0;
      width: 30%;
      display: flex;
      align-items: center;

      @media screen and (max-width: $mobile_width) {
        width: unset;
      }
    }

    .iso-zone {
      width: 70%;
      display: flex;
      justify-content: flex-end;

      @media screen and (max-width: $mobile_width) {
        width: 100%;
      }

      .select {
        width: 30%;
        margin-left: 10px;
        margin-right: 10px;

        @media screen and (max-width: $mobile_width) {
          width: unset;
        }
      }
    }

    margin-bottom: 40px;
  }

  .chart-container {
    height: 300px;
  }
}
