@import "common";
.same-day-market {
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 100%;

    .date-picker-wrapper .date-picker {
      margin: 0;
    }

    .same-day-controls {
      align-items: center;
      border-top: 2px solid rgba(209,209,209,0.4);
      border-bottom: 2px solid rgba(209,209,209,0.4);
      display: flex;
      flex-shrink: 0;
      height: 72px;
      justify-content: space-between;
      padding: 0 30px;
      width: 100%;

      .right-controls {
        display: flex;
        align-items: center;

        .download-button {
          margin-right: 20px;

          .icon-container {
            color: $medium-blue;
          }

          &:disabled {
            .icon-container {
              color: grey;
            }
          }
        }
      }

      @media screen and (max-width: $mobile_width) {
        flex-direction: column;
        align-items: center;
        height: unset;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .threshold-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 300px;

        p {
          color: $dark-gray;
          font-size: 18px;
          line-height: 21px;
          margin-bottom: 7px;
          width: 120px;
        }

        .slider-container {
          align-items: center;
          display: flex;
        }

        .simple-slider {
          height: 14px;
          margin-left: 20px;
          width: 200px;
        }
      }

      h4 {
        color: $header-blue;
        font-weight: 500;
        font-size: 18px;
        margin: 0;
        width: 90px;
      }
    }

    .same-day-contents {
      padding: 0px 30px;
      margin-top: 30px;
      overflow-y: auto;

      @media screen and (max-width: $mobile_width) {
        padding: 0;
      }
    }
  }
