@import "common";
.toggleswitch {
  position: relative;
  width: 42px;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select: none;

  .toggleswitch-checkbox {
    display: none;
  }
  .toggleswitch-label {
    background-color: #C6C5C5;
    display: block;
    overflow: hidden;
    cursor: pointer;
    height: 18px;
    padding: 0;
    line-height: 18px;
    border: 0px solid #999999;
    border-radius: 25px;
  }
  .toggleswitch-label:before {
    background: #F1F1F1;
    content: "";
    display: block;
    width: 25px;
    margin: -3.5px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    border-radius: 25px;
    box-shadow: 0 6px 12px 0px #757575;
  }

  .toggleswitch-checkbox:checked + .toggleswitch-label {
    background-color: #7FBCE0;
  }
  
  .toggleswitch-checkbox:checked + .toggleswitch-label .toggleswitch-inner {
    margin-left: 0;
  }
  .toggleswitch-checkbox:checked + .toggleswitch-label:before {
    background-color: #0079C1;
    right: 0px; 
    box-shadow: 3px 6px 18px 0px rgba(0, 0, 0, 0.2);
  }

  .toggleswitch-disabled {
    cursor: not-allowed;
  }

  .toggleswitch-checkbox:checked + .toggleswitch-label, .toggleswitch-checkbox:checked + .toggleswitch-label:before {
    border-color: #7FBCE0;
  }
}
