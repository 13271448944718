@import "common";
.market-settings-section {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 36px;

  .left-col {
    width: 200px;
    height: 100%;
  }

  .main {
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 100%;
  }

  .title {
    color: $pacific-blue;
    font-size: 18px;
    line-height: 36px;
    display: flex;
    align-items: center;
  }
}
