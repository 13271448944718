@import "common";
.login-container {
  @extend .full-size-flex;
  @extend .flex-centered;
  height: 100%;
  width: 100%;
  background:
    radial-gradient($light-gray 1px, transparent 16%) 0 0,
    radial-gradient($light-gray 1px, transparent 16%) 25px 25px;
  background-color:$white;
  background-size:25px 25px;

  .center-modal {
    @extend .flex-centered;
    height: 340px;
    width: 310px;
    border-radius: 8px;
    background-color: $white;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.25);
    padding: 19px 31px;
    flex-direction: column;
  }

  .logo-container {
    height: 80px;
    width: 100%;
    flex-shrink: 0;
    padding: 10px 0;
    border-bottom: 2px solid $light-gray;
  }

  .gridos-logo {
    height: 100%;
    width: 100%;
  }

  .contents-container {
    @extend .flex-centered;
    height: 100%;
    width: 100%;
    flex-direction: column;
  }

  .custom-btn.login-btn {
    width: 120px;
    height: 40px;
    background-color: $medium-blue;
    padding: 0;
    margin: 0;
    &::focus {
      outline: 0 !important;
    }

    &:hover {
      background-color: $medium-blue;
    }

    &__img {
      padding: 0;
      margin: 0;
    }
  }

  .spinner {
    width: 180px;
    height: 76px;
  }
}

