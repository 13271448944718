@import "colors";
@import "font_sizes";
@import "positions";

$mobile_width: 1024px;

button {
  padding: 0;
}

input {
  background-color: rgba(209,209,209,0.3);
  border: none;
  border-radius: 4px;
  color: $dark-gray;
  font-size: 18px;
  padding: 5px;
}


.control-btn {
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  height: 36px;
  padding: 0 16px;
  min-width: 85px;
  border-radius: 22px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  &:disabled {
    cursor: not-allowed;
  }
}

.white-btn {
  background-color: $white;
  color: $medium-blue;
}

.blue-btn {
  background-color: $medium-blue;
  color: $white;
}

.edit-btn {
  @extend .white-btn
}
.save-btn {
  @extend .blue-btn
}
.cancel-btn {
  background-color: $very-light-gray;
  color: $medium-gray;
}
