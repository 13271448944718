@import "common";
.shifting-bids-table {
  &-delete {
    &:hover {
      color: darkred;
    }
  }

  &-submit {
    color: inherit;
    &:hover {
      color: green;
    }
  }
}
