@import "common";
.modal {
  @extend .flex-centered;

  /* MODAL OVERLAY */
  .modal-overlay {
    background-color: $black-70;
    height: 100vh;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 1000;
    // Show the modal
    &--active {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .modal-body-wrapper {
    @extend .flex-centered;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }

  /* MAIN MODAL BODY INCLUDING HEADER & FOOTER */
  .modal-body {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 8px 18px 0 rgba(0,0,0,0.5);;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    overflow: hidden;
    padding: 20px;
    pointer-events: none;
    position: relative;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    transition: transform 0.5s, opacity 0.5s;
    z-index: 10000;
    &--active {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0);
    }
  }

  /* MODAL HEADER */
  .modal-header {
    align-items: center;
    border-radius: 5px 5px 0 0;
    color: $medium-charcoal;
    display: flex;
    flex-shrink: 0;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
  }

  /* MODAL TEXT BODY */
  .modal-message {
    color: $dark-charcoal;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-size: $body-text;
    height: 100%;
    padding: 5px 0;
    width: 100%;
  }

  /* MODAL FOOTER WITH BUTTONS */
  .modal-footer {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: flex-end;
    padding: 10px 0 0 0;
    width: 100%;

    &__btn {
      border: none;
      color: $white;
      cursor: pointer;
      min-width: 75px;
      text-transform: capitalize;
      &:last-of-type {
        margin-left: 12px;
      }
    }
  }
}
