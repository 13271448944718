@import "common";
@import 'Header';
@import 'Input';
@import 'Label';
@import 'PanelBorder';
@import '_common.scss';

.customer-info-panel {
    background-color: $white;
    @include panel-border;

    display: flex;
    flex-direction: column;

    &__header {
        @include header();
        padding-top: 19px;
        padding-bottom: 17px;
        padding-left: 25px;
    }

    &__content {
        padding-top: 25px;
        padding-left: 58px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: $mobile_width) {
            padding: 10px;
        }
    }

    &__label {
        @include label();
    }

    &__input {
        @include input();

        &[disabled] {
            background-color: white;
        }
    }
}
