@import "common";
.cancel-events {
  
  .close-cancel-events {
    height: 14px;
    width: 14px;
   }

  .modal-overlay--active {
    opacity: 0.2;
    background-color: $button-gray;
  }

  .modal .modal-body {
    background-color: $white;

    .modal-message {
      padding: 0;
    }
  }

  .cancel-events-modal-contents {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    background-color: #FFFFFF;
    font-family: Roboto;
 
    .cancel-events-close-icon {
       @extend .flex-centered;
       height: 14px;
       width: 14px;
       position: relative;
       margin-top: 15px;
       margin-right: -375px;

       i {
         @extend .flex-centered;
       }

      .cancel-btn-close-icon {
        background-color: #FFFFFF;
        border-color: #FFFFFF;
        box-shadow: 0 2px 5px 0 rgba(255,255,255,1);
        color: #808080;
        &:focus {
          box-shadow: 0 1px 2px 0 rgba(255,255,255, 1);
        }
      }
    }

    .cancel-events-close-icon {
      height: 14px;
      width: 14px;
      position: relative;
      margin-top: 15px;
      margin-right: -375px;
    }
 
    .cancel-text-1 {
      margin-top: 5px;
      height: 44px;
      width: 236px;
      color: #0079C1;
      font-weight: 500;
      text-align: center;
      font-size: 18px;
      line-height: 22px;
    }
 
    .cancel-text-2 {
      height: 19px;
      color: #606060;
      font-size: 16px;
      line-height:19px;
      position: relative;
      margin-left: -70px;
      margin-top: 26px;
      margin-bottom: 8px;
    }
 
    .cancel-text-3 {
      height: 32px;
      width: 278px;
      color: #606060;
      font-size: 14px;
      line-height: 16px;
      margin-top: 17px;
     }
 
     textarea {
       height: 154px;
       width: 338px;
       background-color: rgba(209,209,209,0.3);
       border: none;
       resize: none;
       font-size: 18px;
       border-radius: 4px;
       color: #606060;
       padding: 12px;
     }
     
     textarea::placeholder{
      color: rgba(160,160,160,0.8);
     }
      
    .cancel-events-buttons {
      width: 300px;
      height: 30px;
      display: flex;
      flex-direction: row;
      margin-top: 22px;
      position: relative;
      margin-left: 15px;
 
      .cancel-button {
        width: 136px;
        border-radius: 4px;
 
        &--yes-cancel-disabled {
          background-color: #D1D1D1;
	  color: #FFFFFF;
        }
        
	&--yes-cancel-enabled {
          background-color: #D31145;
	  color: #FFFFFF;
        }
 
        &--go-back {
          background-color: #0079C1;
          margin-left: 10px;
        }
      }
    }
  }
}
