@import "common";
.tippy-tooltip {
  font-family: 'Roboto', sans-serif;
  position: relative;
  display: inline-block;
  padding: 0;
  line-height: 2;
  .tippy-backdrop {
    background-color: #404040 !important;
  }

  .tippy-content {
    border-radius: 2px;
    color: $medium-gray !important;
    font-size: $caption-text;
    line-height: 16px;
    max-width: 200px;
    text-align: left;
    padding: 8px;
  }

  .tippy-popper {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.tip-span {
  padding: 0 !important;
  display: inherit;
}
