@import "common";
@import '_common.scss';

.datetime-offer {
  display: flex;
  flex-direction: column;
  width: 100%;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    line-height: 36px;
    margin: 4px 0px;
    width: 100%;
  }

  &-header-content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .btn-group {
      display: flex;
      justify-content: flex-end;

      .option-group {
        max-width: 300px;
      }
    }
  }

  &-title {
    font-size: 18px;
    width: 20%;
  }

  &-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  &-row-title {
    width: 20%;
  }

  &-row-content {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .row-input {
      display: flex;
      align-items: center;
      margin-left: 6px;
      color: $dark-gray;
      font-size: 14px;
    }
  }

  .save-btn {
    @extend .blue-btn;
  }

}
