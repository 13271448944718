@import "common";
.simple-slider {
  height: 120px;
  width: 100%;

  &.thin {
    .simple-slider-rail {
      height: 7px;
      margin-top: 3px;
    }

    .simple-slider-track {
      height: 7px;
      margin-top: 3px;
    }
  }

  &.thick {
    .simple-slider-rail {
      height: 14px;
    }

    .simple-slider-track {
      height: 14px;
    }
  }

  &.round {
    .simple-slider-rail {
      border-radius: 7px;
    }

    .simple-slider-track {
      border-radius: 7px;
    }
  }

  &-container {
    position: relative;
    width: 100%;
  }

  &-rail {
    cursor: pointer;
    position: absolute;
    width: 100%;
  }

  &-handle {
    position: absolute;
    margin-left: -11px;
    margin-top: -6px;
    z-index: 2;
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  }

  &-track {
    cursor: pointer;
    position: absolute;
    z-index: 1;
  }

  &-tick {
    .simple-slider-tick-line {
      background-color: rgb(200,200,200);
      height: 5px;
      margin-top: 14px;
      position: absolute;
      width: 1px;
    }

    .simple-slider-tick-value {
      font-size: $caption-text;
      margin-top: 22px;
      position: absolute;
      text-align: center;
    }
  }
}
