@import "common";
@import '_common.scss';

$body-text: 15px;
$title-text: 18px;
$caption-text: 12px;
$icon-size: 24px;

.pricing-events-list {
  display: flex;
  flex-direction: column;

  .pricing-events-header {
    display: flex;
    flex-basis: 40px;
    padding-left: 40px;
    position: sticky;
    top: 0;
    background-color: #FAFAFA;

    @media screen and (max-width: $mobile_width) {
      margin-left: 10px;
      margin-right: 10px;
      padding: 0;
    }

    &-cell {
      display: flex;
      align-items: center;
      h2 {
        font-weight: normal;
        font-size: $body-text;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .pricing-events-contents {
    max-height: 100%;
    min-height: 50%;
  }

  .pricing-events-hour-container {
    margin: 0px 5px 10px 5px;
    border-radius: 5px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .pricing-events-hour-header {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: flex-start;
    padding-left: 40px;
    width: 100%;
    border: none;
    cursor: pointer;

    @media screen and (max-width: $mobile_width) {
      padding-left: 10px;
      padding-right: 10px;
    }

    .pricing-events-hour-cell {
      text-align: left;
      display: flex;

      .committed-value {
        color: #78A22F;
      }

      &--bold {
        font-weight: bold;
      }

      .pricing-events-hour__btn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: $title-text;
        padding: 10px;
      }
    }

    .pricing-events-hour-toggle {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;

      @media screen and (max-width: $mobile_width) {
        padding-right: 0;
        flex-grow: 1;
      }
    }

    .edit-btn, .save-btn {
      background-color: $medium-teal;
      height: 35px;
      padding: 5px 0;
      &:hover {
        background-color: #228a86;
      }
      &:disabled {
        background-color: $medium-charcoal;
        color: $medium-gray;
      }

    }

    .cancel-btn {
      background-color: transparent;
      text-decoration: underline;
      color: $medium-gray;
      &:hover {
        color: $white;
      }
    }

    .pricing-event-update {
      &--failure {
        color: #f17d76;
        font-size: $body-text;
        margin-right: 0;
        i {
          margin-right: 5px;
        }
      }

      &--success {
        color: $medium-gray;
        margin-right: 10px;
      }
    }
  }

  .pricing-events-event-container {
    transition: 0.5s ease height;
    height: 100%;
    overflow: hidden;
  }

  /* Styles for the individual pricing event rows */
  .pricing-events-event-row {
    display: flex;
    height: 70px;
    padding-left: 40px;

    @media screen and (max-width: $mobile_width) {
      padding-left: 10px;
      padding-right: 10px;
      flex-direction: column;
      height: unset;
      align-items: center;
      padding-bottom: 10px;
    }

    .pricing-events-asset__btn {
      background-color: transparent;
      border: none;
      // border-bottom: 1px solid #0079C1;
      color: #0079C1;
      cursor: pointer;
      font-size: $body-text;
      text-decoration: underline;
    }

    .pricing-events-event-cell {
      align-items: center;
      display: flex;

      @media screen and (max-width: $mobile_width) {
        flex-direction: column;
        max-width: unset;
        width: unset;
      }

      .committed-value {
        color: #78A22F;
      }

      .event-fuel-cost {
        display: flex;
        flex-direction: row;
        align-items: center;
        .fuel-cost-button {
          border: none;
          outline: none;
          background-color: transparent;
          position: relative;
          .info-icon {
            color: $medium-gray;
            cursor: pointer;
          }
        }
      }

      .names {
        align-items: center;
        justify-content: center;

        .der-name {
          align-self: flex-start;
        }

        .program-name {
          align-self: flex-end;
          color: $medium-gray;
          font-weight: lighter;
        }
      }
    }

    @mixin rounded-display($min-width) {
      @extend .flex-centered;
      background-color: transparent;
      border-radius: 5px;
      cursor: default;
      font-size: $body-text;
      min-width: $min-width;
      padding: 5px;
    }

    .pricing-event-status {
      @include rounded-display(130px);
    }

    .pricing-event-settlement {
      @include rounded-display(140px);
    }

    .pricing-event-accept__btn {
      background-color: $medium-teal;
      font-size: $body-text;
      padding: 5px 10px;
      min-width: 100px;
      margin-left: 10px;
    }

    /* Styles for the MPI event accept/reject/counter widget */
    .response-widget {
      display: flex;
      margin-left: 30px;
    }

    .response-btn {
      @extend .flex-centered;
      cursor: pointer;
      height: 35px;
      background-color: $white;
      border-radius: 40px;
      width: 35px;
      border: none;
      margin: 0 5px;

      i {
        @extend .flex-centered;
      }

      &--reject {
        box-shadow: 0 2px 5px 0 rgba(132,16,16,0.36);
        color: #d53831;
        &:focus {
          box-shadow: 0 1px 2px 0 rgba(132,16,16,0.36);
        }
      }

      &--accept {
        box-shadow: 0 2px 5px 0 rgba(48,116,17,0.36);
        color: #568a28;
        &:focus {
          box-shadow: 0 1px 2px 0 rgba(48,116,17,.36);
        }
      }
    }
  }

  .change-reason-row {
    justify-content: flex-end;
    height: 100px;
    .pricing-events-event-cell {
      height: 100px;
    }
    textarea {
      border-radius: 5px;
      height: 75px;
      padding: 5px;
      width: 100%;
      margin-right: 20px;
    }
  }

  .cell-lg {
    width: 50%;

    @media screen and (max-width: $mobile_width) {
      max-width: calc(50% - 5px);
      margin-right: 5px;
    }
  }

  .cell-md {
    width: 25%;

    @media screen and (max-width: $mobile_width) {
      max-width: calc(25% - 5px);
      margin-right: 5px;
      text-overflow: ellipsis;
      overflow: hidden;

      p {
        width: 100%;
      }
    }
  }

  .cell-hide-mobile {
    @media screen and (max-width: $mobile_width) {
      display: none !important;
    }
  }

  .cell-sm {
    width: 16.67%;
    flex-shrink: 0;

    @media screen and (max-width: $mobile_width) {
      max-width: calc(16.67% - 5px);
      margin-right: 5px;
      text-overflow: ellipsis;
      overflow: hidden;

      p {
        width: 100%;
      }
    }
  }

  .cell-left {
    justify-content: flex-start;
  }

  .cell-center {
    justify-content: center;
  }

  .pricing-events-header-cell {
    color: #A0A0A0;
  }

  .pricing-events-hour-container {
    border-radius: 4px;
    box-shadow: 2px 2px 8px 0 rgba(0,0,0,0.15);
  }

  .pricing-events-hour-header {
    background-color: $white;
    color: $dark-charcoal;

    .pricing-events-hour-cell {
      .pricing-events-hour__btn {
        color: $medium-gray;
      }
    }

    .edit-btn, .save-btn {
      background-color: #F78F1E;
      &:hover {
        background-color: #F78F1E;
      }
      &:disabled {
        background-color: #F5F5F5;
        color: #D1D1D1;
      }

    }

    .cancel-btn {
      color: $medium-gray;
      &:hover {
        color: $medium-gray;
      }
    }

    .pricing-event-update {
      &--failure {
        color: #f17d76;
      }

      &--success {
        color: $medium-gray;
        margin-right: 10px;
      }
    }
  }

  .pricing-events-event-container {
    background-color: $white;
    border-top: 1px solid rgba(151, 151, 151, 0.19);
  }

  .pricing-events-event-row {
    background-color: $white;
    color: $dark-charcoal;

    .pricing-events-event-cell {
      color: $dark-charcoal;
    }

    @mixin pricing_event_status_theme($color) {
      border: 1px solid $color;
      color: $color;
    }

    .pricing-event-status {
      @include pricing_event_status_theme($medium-gray);
      border-radius: 15px;
      min-width: 100px;

      &--accepted {
        @include pricing_event_status_theme(#78A22F);
      }

      &--active {
        @include pricing_event_status_theme(#78A22F);
      }

      &--cancelled {
        @include pricing_event_status_theme(#D0021B);
      }

      &--rejected {
        @include pricing_event_status_theme(#D0021B);
      }

      &--confirmed {
        @include pricing_event_status_theme(#009DDC);
      }

      &--countered {
        @include pricing_event_status_theme(#FF7F00);
      }
    }

    .pricing-event-settlement {
      @include pricing_event_status_theme($medium-gray);
      border-radius: 15px;
    }

    .pricing-event-accept__btn {
      background-color: $medium-teal;
    }
  }

  .change-reason-row {
    textarea {
      background-color: $transparent-gray;
      font-size: $body-text;
      color: $dark-charcoal;
      border: none;
    }
  }


  .fuel-breakdown {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 250px;
    box-shadow: 0 2px 4px 0 rgba(38,38,38,0.5);
    padding: 10px;
    z-index: 9999;
    .breakdown-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: $body-text;
      text-align: left;
      padding-bottom: 10px;
      .fuel-popover {
        border: none;
        outline: none;
        background-color: transparent;
        .breakdown-dismiss {
          color: $medium-gray;
          font-size: $caption-text;
          cursor: pointer;
        }
      }
    }
    .breakdown-row {
      display: flex;
      justify-content: space-between;
      padding-bottom: 7px;
      &:last-child {
        padding-bottom: 0;
      }
    }
    .separator {
      width: 100%;
      border-top: 1px solid $light-gray-border;
      height: 1px;
      padding-bottom: 7px;
    }
  }
}

@keyframes highlight {
  0% {
    background: #50c7c2;
  }
  100% {
    background: none;
  }
}

.highlight {
   animation: highlight 5s;
}

@media (max-width: 1400px) {
  .pricing-events-container {
    .pricing-events-event-row {
      .pricing-event-status__btn, .pricing-event-accept__btn {
        font-size: $body-text;
        min-width: 75px;
      }
    }
  }
}

@media (max-width: 1080px) {
  .pricing-events-list .pricing-events-event-row {
    .response-widget {
      margin-left: 10px;
    }

    .response-btn {
      width: 30px;
      height: 30px;
      i {
        font-size: $icon-size;
      }
    }

    .pricing-event-status, .pricing-event-settlement {
      min-width: 70px;
      font-size: $caption-text;
      padding: 5px;
    }
  }
}

@media (max-width: 915px) {
  .pricing-events-list .pricing-events-event-row {

    .response-btn {
      width: 25px;
      height: 25px;
      i {
        font-size: $icon-size;
      }
    }

    .pricing-event-status, .pricing-event-settlement {
      min-width: 70px;
      font-size: $caption-text;
      padding: 5px;
    }
  }
}
