$success: #6D982A;
$error: #CA001A;
$error-lighter-red: #D53831;

$white : #FFF;
$white-20: rgba(255, 255, 255, 0.2);
$white-50: rgba(255, 255, 255, 0.5);
$white-70: rgba(255, 255, 255, 0.7);

$off-white: #F7F7F7;

$very-light-teal: #50c7c2;
$light-teal: #06AFA8;
$medium-teal: #0A7E79;
$dark-teal: #005753;

$teal-stripes: linear-gradient(45deg, $very-light-teal 0%, $very-light-teal 30%,
  $light-teal 30%, $light-teal 70%, $very-light-teal 70%);
$teal-thin-stripes: repeating-linear-gradient(135deg, $medium-teal 0%,
  $medium-teal 2%, $light-teal 2%, $light-teal 4%);

$pacific-blue: #009DDC;
$navy-blue: #0079C1;
$blue-gray: #8894AF;

$light-green: #c1f5c2;
$dark-green: #008000;

$light-red: #ffcccc;
$salmon: #FC7169;

$light-gray-border: #E0E0E0;
$lighter-gray: #a0a0a0;
$lighter-gray-opacity-70: rgba(160,160,160,0.7);
$very-light-gray: #F1F2F2;
$light-gray: #D4D4D4;
$very-lighter-gray: #cccccc;
$medium-gray: #949899;
$medium-gray-90: #909090;
$dark-gray: #606060;
$dark-gray-30: #303030;
$darker-gray: #404040;
$transparent-gray: rgba(209,209,209,0.3);

$medium-charcoal: #383838;
$dark-charcoal: #262626;

$panel-gray: #1b1b1b;
$panel-black: #151515;

$black: #000;
$black-10: rgba(0,0,0,0.1);
$black-20: rgba(0,0,0,0.2);
$black-60: rgba(0,0,0,0.6);
$black-70: rgba(0,0,0,0.7);

$warning-red: #FF3A00;

$very-light-blue: #aed4e8;
$light-blue: #4495be;
$light-header-blue: #009DDC;
$medium-blue: #0274b7;
$dark-blue: #003E52;
$header-blue: #0079C1;
$grad-dark: #003E52;

$white-smoke: #F5F5F5;
$very-light-gray: #EEEEEE;
$light-gray: #D1D1D1;
$button-gray: #A0A0A0;

// Text Colors
$text-main: $dark-charcoal;
$text-disabled: #CCCCCC;

.full-size-flex {
  height: 100%;
  width: 100%;
  display: flex;
}

.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-spaced-columns {
  @extend .flex-column;
  justify-content: space-between;
}
