@import "common";
.custom-btn {
  @extend .flex-centered;
  border: none;
  border-radius: 4px;
  color: $white;
  cursor: pointer;
  font-size: $body-text;
  height: 36px;
  overflow: hidden;
  padding: 0 12px;

  .btn-loading {
    margin-left: 10px;
  }

  &.primary-btn {
    background-color: $light-teal;
    &:hover {
      background-color: $medium-teal;
    }
  }

  &.secondary-btn {
    background-color: transparent;
    border: 1px solid #606060;
    color: #606060;
    &:hover {
      background-color: #F5F5F5;
    }
  }

  &.text-btn {
    background-color: transparent;
    color: $light-teal;
    font-size: $body-text;
    padding: 0;
    &:disabled {
      color: #cccccc;
      cursor: default;
    }
  }

  &.tour-btn {
    background-color: white;
    color: #1675EB;
    &:hover {
      background-color: #f5f5f5;
    }
  }

  &.primary-btn, &.secondary-btn, &.tour-btn {
    &:disabled, &:disabled:hover, &:disabled:focus {
      border: none;
      cursor: default;
      background-color: #e3e3e3;
      color: #aaaaaa;
    }  
  }

  &.circular-type {
    padding: 0.5em;
    border-radius: 50%;
  }  
}
