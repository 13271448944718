@import "common";
.wrapped-select {
  width: 100%;
  display: flex;
  flex-direction: column;

  .base-select {
    padding-left: 10px;
  }

  &--row {
    flex-direction: row;
    align-items: center;

    .base-select {
      flex-grow: 1;
    }
  }
  
  .label {
    font-size: 15px;
  }
}
