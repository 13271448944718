@import "common";
@import '_common.scss';

.header-navigation-bar {
  background-color: $white-smoke;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 72px;
  padding: 13px 0;
  width: 100%;
  justify-content: space-between;
  z-index: 10;
  align-items: center;

  .center-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    @media screen and (max-width: $mobile-width) {
      flex-direction: column;
    }
  }

  .header-container {
    display: flex;
    width: 100%;
    justify-content: center;

    @media screen and (max-width: $mobile-width) {
      width: unset;
    }
  }

  .header-title {
    flex-shrink: 0;
    padding-left: 30px;
    align-self: center;
    margin: 0;
    color: $dark-blue;
    flex-shrink: 0;
    font-size: 24px;
    font-weight: 500;

    @media screen and (max-width: $mobile-width) {
      padding-left: 0;
    }
  }

  .nav-menu-btn {
    display: none;
    margin-left: 10px;

    @media screen and (max-width: $mobile-width) {
      display: block;
    }
  }
}


.dropdown-menus {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 30px;
  flex-shrink: 0;
  align-items: center;

  @media screen and (max-width: $mobile-width) {
    padding-right: 10px;
  }

  button {
    background: none;
    border: none;
    &:hover { background-color: #E5E5E5; }
  }

  .dropdown-menu-container {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 10px;

    @media screen and (max-width: $mobile-width) {
      margin-right: 0;
    }

    .dropdown-menu-selector {
      display: inline-flex;
      vertical-align: middle;
      justify-content: center;

      border-radius: 3px;
      width: 30px;
      height: 30px;
      padding: 0;

      color: $dark-blue;
      &--active, &:hover {
        color: #0398e6;
      }
    }

    .dropdown-menu {
      position: absolute;
      z-index: 2000;
      right: 0px;
      top: 35px;
      overflow: hidden;

      background-color: white;
      border-radius: 4px;
      padding: 0;
      width: 250px;

      max-height: 0px;
      transition: 0.1s ease max-height;
      &--active {
        box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.2);
        max-height: 500px;
      }

      &-header {
        color: white;
        background-color: $header-blue;
        font-weight: 500;
        margin-bottom: 10px;
        padding: 15px 25px;
        font-size: 17px;
        .user-email { font-size: 12px; }
      }

      &-item {
        border: none;
        color: $dark-gray;
        width: 100%;
        text-align: left;
        line-height: 40px;
        padding-left: 25px;
        font-weight: 500;
        font-size: 16px;

        &:last-child {
          margin-top: 5px;
          border: 1px solid $very-light-gray;
          color: $header-blue;
          line-height: 40px;
        }
      }
    }
  }
}
