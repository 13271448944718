@import "common";
@import '_common.scss';

.generation-chart-panel {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 400px;
  width: 100%;

  @media screen and (max-width: $mobile_width) {
    height: 350px;
  }

  .generation-schedule-chart {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    padding: 44.2px 82.86px 10px 41px;
    width: 100%;

    @media screen and (max-width: $mobile_width) {
      padding: 10px;
    }
  }
}
