@import "common";
.profile-asset {
    display: flex;
    flex-direction: row;
    height: 60px;

    &__icon {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.15);
    }

    &__text-container {
        display: flex;
        flex-direction: column;
        padding-top: 6.5px; // (50 - 21 - 16) / 2
        padding-left: 22px;
    }

    &__name {
        font-size: 18px;
        line-height: 21px;
        color: $dark-gray;
    }

    &__class {
        font-size: 14px;
        line-height: 16px;
        color: $light-header-blue;
    }
}