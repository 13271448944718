@import "common";
@import '~@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';
@import "~@fortawesome/fontawesome-free-webfonts/css/fa-solid.css";

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

button,
input,
optgroup,
select,
textarea {
  font-family: 'Roboto', sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

p {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// For what-input library. Disables focus outline on mouse interaction
[data-whatintent="mouse"] *:focus {
  outline: none;
}

[data-whatintent="mouse"] *::-moz-focus-inner {
  border:0;
}

.MPI {
  height: 100vh;
  display: flex;
  font-family: 'Roboto', sans-serif;
  overflow-y: hidden;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
    url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
    url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
