@import "common";
.home-page {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .date-picker-wrapper .date-picker {
    margin: 0;
  }
}

.homepage-contents {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .events-list {
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    width: 100%;

    .event-list-header {
      flex-shrink: 0;
      border-top: 2px solid rgba(209,209,209,0.4);
      border-bottom: 2px solid rgba(209,209,209,0.4);

      color: $header-blue;
      font-weight: 500;
      font-size: 18px;
      line-height: 74px;
      margin: 0;
      padding: 0 30px;
    }

    .events-list-contents {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 30px;
    }
  }

  // Styles for the NoCommittedEvents and NoEnrolledAssets placeholder components
  .events-placeholder {
    @extend .flex-centered;
    border-top: 2px solid rgba(209, 209, 209, 0.4);
    color: $dark-gray;
    flex-direction: column;
    justify-content: space-evenly;

    .events-message {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        font-weight: 500;
        margin: 0 0 20px 0;
      }

      p {
        color: $medium-gray;
      }
    }

    .market-links {
      margin-top: 40px;
    }

    &__img {
      max-height: 170px;
    }

    .primary-btn {
      background: linear-gradient(180deg, $header-blue 0%, $dark-blue 100%);
      padding: 8px 10px;
      margin: 10px 5px 0 5px;
      font-size: 16px;
    }
  }

  @media (max-height: 700px) {
    .events-placeholder {
      padding: 10px;

      .market-links {
        margin-top: 10px;
      }

      img {
        max-height: 120px;
      }
    }
  }

  .loading-dots {
    margin: 100px auto 0;
    text-align: center;
    width: 150px;

    & > div {
      animation: fading-dots 1.4s infinite ease-in-out both;
      background-color: $medium-gray;
      border-radius: 100%;
      display: inline-block;
      margin: 0 10px;
      height: 20px;
      width: 20px;
    }

    .bounce1 {
      animation-delay: -0.32s;
    }

    .bounce2 {
      animation-delay: -0.16s;
    }

    @keyframes fading-dots {
      0%, 80%, 100% {
        transform: scale(0);
      } 40% {
        transform: scale(1.0);
      }
    }
  }
}

.area-chart-panel {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 225px;
  width: 100%;
}

.generation-schedule-chart {
  display: flex;
  overflow: hidden;
  padding: 44.2px 82.86px 10px 41px;
  width: 100%;
}
