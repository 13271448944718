@import "common";
@import 'Header';
@import 'PanelBorder';

.profile-asset-list {
    display: flex;
    flex-direction: column;
    background: $white;

    @include panel-border();

    &__header {
        @include header();
        padding-left: 24px;
        padding-top: 21px;
        padding-bottom: 17px;
    }

    &__ders {
        border-bottom: 1px solid $very-light-gray;
        padding-top: 25px;
    }

    &__der {
        padding-left: 24px;
    }

    &__footer {
        font-size: 14px;
        height: 50px;
        padding-left: 24px;
        padding-top: 18px;
        padding-bottom: 18px;
        text-align: left;

        .asset-link {
            color: $header-blue;
        }
    }
}