@import "common";
.bid-offer-section {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    color: $pacific-blue;
    font-size: 22px;
    line-height: 36px;
    width: 20%;
    height: 100%;
  }
}
