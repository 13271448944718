@import "common";
.breadcrumbs {
  &-container {
    display: flex;
    align-items: center;
    margin-left: 20px;
    width: 100%;
  }
  &-separator {
    font-size: 2em;
    margin: 0 10px;
    font-weight: 100;
  }
  &-label {
    color: $dark-blue;
  }
}
