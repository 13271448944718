@import "common";
.event-display-container {
  align-items: center;
  display: flex;
  flex-direction: row;

  .event-display {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }
}