@import "common";
@import '_common.scss';

.btn-group {
  display: flex;
  justify-content: space-between;
  margin-left: auto;

  .delete-btn {
    margin: 0px 2px;
  }

  .save-btn {
    @extend .blue-btn;
    margin: 0px 2px;
  }
}
