@import "common";
@import '_common.scss';

.mpi-left-rail {
  height: 100%;
  background-color: $header-blue;
  background: linear-gradient($header-blue, $grad-dark);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-shrink: 0;
  overflow-y: auto;
  width: 200px;

  @media screen and (max-width: $mobile-width) {
    // Below 800 PX wide, mobile layout
    position: absolute;
    display: none;
    width: 100%;
    padding-top: 75px; // Moves the menu below the header
    z-index: 10;

    &.visible {
      display: flex;
    }
  }

  .left-rail-bottom {
    color: $white;
    padding: 20px;
    .version {
      font-size: 10px;
      padding-top: 6px;
      opacity: 0.6;
    }
  }

  .left-rail-header {
    padding: 15px 20px 15px 20px;
    user-select: none;

    .logo {
      max-width: 164px;
      margin-bottom: 18px;
    }
  }

  .home-container {
    border-top: 1px solid rgba(255,255,255,0.3);;
    border-bottom: 1px solid rgba(255,255,255,0.3);;
  }

  .market-btn-container {
    border-bottom: 1px solid rgba(255,255,255,0.3);
    padding: 12px 0;
  }

  .info-btn-container {
    padding-top: 5px;
  }

  .gridOS-logo {
    height: 24px;
    margin-top: 5px;
  }

  // NavigationButton Styles
  .nav-button-container {
    position: relative;
    width: 100%;
  }

  .nav-button {
    align-items: center;
    background-color: transparent;
    border: none;
    color: $very-light-blue;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    justify-content: center;
    margin: 0;
    padding: 0 20px;
    position: absolute;
    text-align: left;
    width: 100%;

    &--selected {
      background-color: $dark-blue;
    	box-shadow: 0 2px 4px 0 #00467F;
      z-index: 100;
    }
  }

  .nav-button-contents {
    align-items: center;
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    width: 100%;
    margin: 0;
    line-height: 20px;
  }

  .nav-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    margin-right: 15px;
    width: 36px;
    height: 36px;
    flex-shrink: 0;
  }
}
