@import "common";
@import 'Header';
@import 'Input';
@import 'Label';
@import 'PanelBorder';
@import '_common.scss';

// Need this for the company info panel, so load it here
$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome.scss";

.company-info-panel {
    background-color: $white;
    @include panel-border;

    display: flex;
    flex-direction: column;
    margin-top: 25px;
    margin-bottom: 50px;

    @media screen and (max-width: $mobile_width) {
        margin-bottom: 0;
    }

    &__header {
        @include header();
        padding-left: 25px;
        padding-right: 19px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .text {
            margin-top: 19px;
            margin-bottom: 17px;
            flex-grow: 1;
        }
    }

    &__edit-button {
        width: 100px;
        font-size: 18px;
        line-height: 21px;
        box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
        color: $header-blue;
        background-color: $white;
        border-radius: 22px;
        height: 44px;
        margin-top: 8.5px;
        border: none;
    }

    &__error {
        font-size: 18px;
        line-height: 21px;
        color: $warning-red;
        margin-top: 19px;
        padding-right: 10px;
    }

    &__cancel-button {
        font-size: 18px;
        line-height: 21px;
        border: none;
        color: $button-gray;
        text-decoration: underline;
        background-color: white;
    }

    &__save-button {
        margin-left: 17px;
        margin-top: 8.5px;
        color: $white;
        background-color: $header-blue;
        background: linear-gradient($header-blue, $grad-dark);
        border-radius: 22px;
        font-size: 18px;
        line-height: 21px;
        height: 44px;
        width: 100px;
    }

    &__sub-header {
        border-top: 1px solid $very-light-gray;
        padding-top: 19px;
        padding-bottom: 17px;
    }

    &__info-panel {
        padding-left: 38px;
        padding-right: 32px;
        padding-bottom: 25px;
        display: flex;
        flex-direction: row;

        @media screen and (max-width: $mobile_width) {
            padding: 10px;
        }

        &-edit {
            .panel-column {
                @media screen and (max-width: $mobile_width) {
                    width: calc(100% - 30px) !important;
                }
            }

            @media screen and (max-width: $mobile_width) {
                flex-direction: column;
            }
        }
    }

    &__address-container {
        display: flex;
        flex-direction: row;
    }

    .panel-column {
        width: 50%;
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        margin-right: 20px;

        @media screen and (max-width: $mobile_width) {
            margin-left: 5px;
            margin-right: 5px;
            width: calc(50% - 10px);
        }
    }

    .address-column {
        width: 50%;
        display: flex;
        flex-direction: column;

        &__right {
            margin-left: 20px;
        }
    }

    &__group {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
    }

    &__label {
        @include label();
    }

    &__input {
        @include input();

        &--edit {
            background-color: $very-light-gray;
        }

        &[disabled] {
            background-color: white;
        }
    }

    &__address {
        resize: none;
    }
}
