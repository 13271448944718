@import "common";
@import '_common.scss';

.assets-container {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;

  .scroll-container {
    height: 100%;
    overflow-y: auto;
  }

  .asset-controls {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 1;
    height: 65px;
    justify-content: flex-end;

    .control-btn {
      margin-left: 20px;

      @media screen and (max-width: $mobile_width) {
        margin: 0;
      }
    }

    .asset-controls-feedback {
      color: $dark-gray;
      margin-right: 15px;
    }

    .enroll-asset-btn {
      @extend .blue-btn;
    }
  }

  .activate-deactivate-btn {
    height: 40px;
    width: 104px;
    color: $dark-gray;
    font-size: 16px;
    background-color: transparent;

    border: 1px solid $light-gray;
    border-radius: 4px;
  }

  .assets-content {
    border-top: 2px solid rgba(209,209,209,0.3);
    padding: 0 28px;
    // 72px for top header + 65px for tabs + 10px for margin at the bottom
    // This is needed so that the height of the scroll div is correct
    height: calc(100% - 147px);
    width: 100%;

    @media screen and (max-width: $mobile_width) {
      padding: 0 5px;
    }
  }

  .assets-table {
    padding: 5px 0;
    text-align: center;

    &__header-container {
      display: flex;
      align-items: center;
      height: 55px;
      margin: 0;
      padding-left: 17px;
      padding-top: 0px;
      padding-right: 46px;
      padding-bottom: 0px;

      @media screen and (max-width: $mobile_width) {
        padding: 0;
      }
    }

    &--hide-mobile {
      @media screen and (max-width: $mobile_width) {
        display: none;
      }
    }

    &__asset-titles {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      padding-left: 46px;
      padding-top: 0px;
      padding-right: 46px;
      padding-bottom: 20px;
      color: $button-gray;

      @media screen and (max-width: $mobile_width) {
        padding: 0;
        justify-content: space-between;
      }
    }

    &__asset-row {
      background-color: $white;
      color: $dark-gray;
      display: flex;
      align-items: center;
      height: 55px;
      margin: 10px 0;
      padding-left: 17px;
      padding-top: 0px;
      padding-right: 46px;
      padding-bottom: 0px;
      border-radius: 5px;
      box-shadow: 2px 2px 8px 0 rgba(0,0,0,0.15);

      @media screen and (max-width: $mobile_width) {
        padding: 0;
        justify-content: space-between;
      }

      &--editing {
        .assets-table__status--enrolled {
          color: #D1D1D1;
          border-color: #D1D1D1;
        }
      }
    }

    &__selected {
      @extend .flex-centered;
      padding-right: 11px;
    }

    &__name {
      width: 12%;
    }

    &__link {
      color: #0079C1;
    }

    &__status {
      width: 12%;
      &--enrolled {
        @extend .flex-centered;
        box-sizing: border-box;
        color: #0079C1;
        height: 29px;
        border: 1px solid #0079C1;
        border-radius: 14px;
        width: 100px;
      }
    }

    &__type {
      width: 9%;
    }

    &__email {
      width: 23%;
    }

    &__enabled {
      width: 14%;
      @extend .flex-centered;

      .check {
        left: 8%;
      }
    }

    &__threshold {
      width: 18%;
      font-size: 18px;

      &--editing p {
        display: inline-block;
      }
    }

    &__accept, &__reject {
      width: 10%;
      justify-content: center;
      display: flex;

      .tooltip-contents {
        background-color: #616161;
        color: white;
        font-size: 10px;
        padding: 8px;
        line-height: 12px;
        height: 62px;
        width: 100px;
        border-radius: 2px;
      }

      .toggleswitch {
        width: 40px;
      }
    }

    &__input {
      height: 36px;
      margin: 0 5px;
      width: 90px;
    }
  }

  .react-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &__tab-list {
      display: flex;
      flex-direction: row;
      font-size: 16px;
      padding-left: 28px;
      padding-right: 28px;
      margin: 0px;

      @media screen and (max-width: $mobile_width) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    &__tab {
      display: inline-block;
      border-bottom: none;
      list-style: none;
      width: 150px;
      padding-top: 21px;
      text-align: center;
      cursor: pointer;
      color: $dark-gray;

      @media screen and (max-width: $mobile_width) {
        min-width: 75px;
      }

      &--selected {
        border-bottom: 5px solid $medium-blue;
        color: $medium-blue;
      }

      &--disabled {
        cursor: default;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 5px hsl(208, 99%, 50%);
        border-color: hsl(208, 99%, 50%);
      }
    }

    &__tab-panel {
      display: none;

      &--selected {
        display: flex;
        height: 100%;
      }
    }
  }

}
