@import "common";
@import "~progress-tracker/app/styles/progress-tracker.scss";

.registration-container {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .asset-registration-navigation {
    border-bottom: 2px solid $very-light-gray;
    height: 72px;
    width: 100%;

    .back-navigation-btn {
      align-items: center;
      background-color: transparent;
      border: none;
      color: $header-blue;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      font-weight: 500;
      height: 100%;
      text-decoration: none;
      width: 300px;

      i {
        color: $medium-gray;
        font-size: 28px;
        padding: 0 20px;
      }
    }
  }

  .registration-contents {
    padding-bottom: 15px;
    overflow-y: auto;
  }

  .registration-header {
    height: 50px;
    background: linear-gradient(180deg, $header-blue 0%, $dark-blue 100%);

    .ng-logo {
      margin-left: 18px;
      margin-top: 8px;
      height: 33px;
    }

    .dsp {
      height: 50px;
      color: #FFFFFF;
      font-family: Muli;
      font-size: 24px;
      line-height: 42px;
      vertical-align: bottom;
      margin-left: 16px;
    }
  }

  .progress-container {
    border-bottom: 2px solid $very-light-gray;
    margin-bottom: 30px;
  }

  &.asset-registration .progress-tracker {
    width: 400px;
    margin: 30px auto 10px auto;
  }

  &.customer-registration .progress-tracker {
    width: 540px;
    margin: 40px auto 5px auto;
  }

  .progress-step {
    &:not(:last-child)::after {
      background-color: $light-gray;
      top: 18px;
      z-index: 0;
    }

    .progress-marker {
      background-color: #FAFAFA;
      border: 3px solid $light-gray;
      color: $light-gray;
      font-size: 18px;
      font-weight: 500;
      height: 41px;
      width: 41px;
    }

    .progress-text {
      color: #FAFAFA;
      padding-bottom: 0;
      h4 {
        font-weight: normal;
      }
    }

    .progress-title {
      font-size: 14px;
      margin-bottom: 0;
    }

    &.is-active,
    &.is-complete {
      .progress-marker {
        border-color: $medium-blue;
        color: $medium-blue;
      }
    }

    &.is-active .progress-text {
      color: $header-blue;
    }

    &.is-complete::after {
      background-color: $header-blue;
    }
  }

  .multi-step {
    width: 400px;
  }

  .registration-title {
    height: 24px;
    color: $header-blue;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    margin-bottom: 16px;
  }

  .registration-info {
    vertical-align:middle;
    height: 24px;
    color: $header-blue;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
    background-color: rgba($light-blue, 0.3);
    box-shadow: #A0A0A0;
    border: 1px solid $header-blue;
    border-radius: 4px;
    height: 40px;
  }

  .registration-error {
    vertical-align:middle;
    height: 24px;
    color: $header-blue;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
    background-color: rgba($light-red, 0.3);
    box-shadow: #A0A0A0;
    border: 1px solid $light-red;
    border-radius: 4px;
    height: 40px;
  }

  .registration-error {
    vertical-align:middle;
    height: 24px;
    color: $header-blue;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
    background-color: rgba($light-red, 0.3);
    box-shadow: #A0A0A0;
    border: 1px solid $light-red;
    border-radius: 4px;
    height: 40px;

  }

  .registration-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .column-container {
      display: flex;
      flex-direction: row;

      .left-column {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-right: 5px;
      }

      .right-column {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-left: 5px;
      }
    }

    .form-field-container {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      .form-field {
        display: flex;
        flex-direction: column;
        width: 80%;
        flex-grow: 1;
      }

      .field-symbol {
        display: flex;
        flex-direction: column;
        width: 20%;
        padding-left: 11px;
        margin-bottom: 10px;
        vertical-align: middle;

        height: 21px;
        color: #9B9B9B;
        font-family: Roboto;
        font-size: 18px;
        line-height: 21px;
      }
    }

    .checkbox-container {
      margin-top: 4px;
      margin-bottom: 4px;
      margin-left: 4px;

      input[type="checkbox"] {
        vertical-align: middle;
      }

      label {
        padding-left: 8px;
      }
    }

    .btn {
      border: none;
      cursor: pointer;
      font-size: 16px;
      font-weight: 100;
      height: 32px;
      width: 100px;
    }

    .footer-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding-top: 24px;
    }

    .btn-prev {
      background-color: $medium-blue;
    }

    .btn-next {
      background-color: $button-gray;
    }

    .btn-prev, .btn-next {
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      color: $white;
      &:disabled {
        cursor: not-allowed;
      }
    }

    .form-field > input {
      margin:0;
      border:0;
      padding: 4px;
      padding-left: 8px;
      display:inline-block;
      vertical-align:middle;
      white-space:normal;
      line-height:2;
      width: 100%;
      font-family: Roboto;
      font-size: 16px;
      height: 40px;
      border-radius: 4px;
      background: rgba($light-gray, 0.3) none;
      margin-top: 2px;
      margin-bottom: 4px;

      &::placeholder {
        color: $light-gray;
      }
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $light-gray;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: $light-gray;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $light-gray;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: $light-gray;
      }

      &:invalid {
        box-shadow: none;
      }

    }

    .field-label {
      color: $header-blue;
      height: 16px;
      font-family: Roboto;
      font-size: 14px;
      line-height: 16px;
      margin: 4px;
    }

    .required-field {
      margin-left: 4px;
      color: red;
      height: 16px;
      font-family: Roboto;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
